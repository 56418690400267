<template>
	<div>
    <div class="header_bg hidden-xs">
      <div class="container">
        <div class="row" style="display:flex;align-items: center;justify-content: space-between">
          <div class="col-sm-2 col-md-2 col-lg-2" style="display:flex;align-items: center;padding: 0;cursor: pointer" @click="$router.push('/')">
            <div class="logo"></div><span>瞄平台</span>
          </div>

          <div class="col-sm-7 col-md-7 col-lg-7" style="position:relative;">
            <!-- 导航栏begin -->
            <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOutLeft">
              <ul v-if="!search_flag" style="position: absolute;top: -19px;left: 0;width: 100%;white-space: nowrap">
				  <router-link v-for="(v,index) in navs" :to="v.to" :key="index">
				  				<!-- <li v-if="v.name=='2024青年电影周（浙江）'" :class="{nav_select:nowNav==v.name}" @click="toMain(v.to)">{{ v.name }}</li> -->
				     <li  @click="nowNav=v.name" :class="{nav_select:nowNav==v.name}">
				       {{ v.name }}
				       <img class="no_affected_size" v-if="nowNav==v.name" src="../assets/Rectangle 1984.png">
				     </li>
				   </router-link>
              <!--  <router-link v-for="(v,index) in navs" :to="v.to" :key="index">
                  <li @click="nowNav=v.name" :class="{nav_select:nowNav==v.name}">
                    {{ v.name }}
                    <img class="no_affected_size" v-if="nowNav==v.name" src="../assets/Rectangle 1984.png">
                  </li>
                </router-link> -->
              </ul>
            </transition>
            <!-- 导航栏end -->

            <!-- 搜索部分begin -->
            <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOutRight">
              <div class="cha" v-if="search_flag" @click="cha_event"></div>
            </transition>
            <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOutRight">
              <!--        <input type="text" placeholder="关键词" v-if="search_flag" ref="search_input">-->
              <!--          <el-input  placeholder="关键词"  ref="search_input"></el-input>-->
              <div class="search_input" v-if="search_flag">
                <input type="text" placeholder="关键词" v-if="search_flag" ref="search_input" v-model="search_value" @keydown.enter="search_event">
              </div>
            </transition>
            <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOutRight">
              <img src="../assets/bigjing.png" alt="搜索" :class="{search_img:search_flag}" v-if="search_flag" @click="search_event">
            </transition>
            <div class="search_jieguo">

            </div>
            <!-- 搜索部分end -->

          </div>

          <div style="display: flex;align-items: center;justify-content: end">
            <!--        <img src="../assets/bigjing.png" class="search_img" alt="搜索">-->
            <img src="../assets/bigjing.png" alt="搜索" v-if="!search_flag"
                 @click="search_event">
<!--            <span class="vertical_line" v-if="!search_flag"></span>-->
<!--            <div class="avatar"><img :src="avatar"></div>
            <button @click="login">{{msg}}</button>-->
          </div>

        </div>
      </div>
    </div>
    <!--移动端导航栏-->
    <div class="m_header visible-xs" v-if="$route.path!='/honors'">
<!--      {{ m_title }}-->
<!--      瞄电影-->
      <img src="../assets/header_logo.png">
      <div class="arrow_left" @click="goTop()" v-if="$route.path!='/index'"></div>
    </div>
  </div>
</template>

<script>
	import {Message} from "element-ui";
  import loding_n from '../assets/login_n.png'
  import loding_s from '../assets/login_s.png'
  export default {
		name: "Header",
    data() {
			return {
				navs: [
          {name:"首页",to:"/index"},
          {name:"新闻中心",to:"/press-center"},
          {name:"农村放映",to:"/present"},
          // {name:"荣誉榜",to:"/honors"},
          {name:"锐影评",to:"/cinecism"},
          {name:"有活动",to:"/event-expo"},
		  // {name:"2024青年电影周（浙江）",to:"https://www.dyz.zjfilm.cn/"}
        ],
				nowNav: "",
				search_flag: false,
				search_value: '',
        msg:'登录/注册',
        avatar:loding_n,
        m_title:this.title,
        befrom:''
			}
		},
    props:{
      info:Boolean,
      title:String
    },
		methods: {
			search_event() {
				if (this.search_flag) {
					//搜索事件
          this.$emit('search_in',this.search_value)
				} else {
					//显示搜索框
					this.search_flag = true
					this.$nextTick(() => {
						this.$refs.search_input.focus();
					})
				}
			},
      cha_event(){
        this.search_flag=false;
        this.$emit('search_in','')
        this.search_value=''
      },
      login(){
        if(this.msg=='退出登录'){
          localStorage.removeItem('token')
          this.msg='登录/注册'
          this.avatar=loding_n
          Message.success('退出成功')
        }else{
          this.$emit('login_in',true)
        }
      },
      goTop(){
        if (window.history.length <= 1) {
          this.$router.push({path:'/'})
          return false
        } else {
          this.$router.go(-1)
        }
      },
	  
	  toMain(href){
	    //前往猫眼 电影详情页面
	    window.location.href = href
	  }
		},
    watch: {
      '$route.path':{
        handler(n){
          /*console.log(n,o);
          for (let i = 0;i<this.navs.length;i++){
            if(this.navs[i].to==n){
              this.nowNav = this.navs[i].to;
              break;
            }
            if(i==this.navs.length-1){
              this.nowNav = this.navs[i].to;
            }
          }*/
          if(this.$phoneFlag){
            console.log(n)
            switch (n){
              case '/index':
                this.m_title='瞄电影'
                break;
              case '/list/news':
                this.m_title='浙江电影界'
                break;
              case '/list/infors':
                this.m_title='电影新闻'
                break;
              case '/list/comments':
                this.m_title='影评'
                break;
              case '/list/trends':
                this.m_title='权威发布'
                break;
              case '/film-week':
                this.m_title='青年电影周'
                break;
              case '/vote/hits':
                this.m_title='互动投票'
                break;
              case '/vote/view':
                this.m_title='互动投票'
                break;
              case '/vote/video':
                this.m_title='互动投票'
                break;
              case '/vote/apply':
                this.m_title='互动投票'
                break;
              case '/vote-main':
                this.m_title='投票详情'
                break;
              case '/activity_main':
                this.m_title='活动'
                break;
              case '/activity_enlist':
                this.m_title='报名'
                break;
              default:
                break;
                /*if(n.indexOf('/details')!=-1){
                  while (1){
                    setTimeout(()=>{
                      console.log(11111)
                      if(localStorage.getItem('details_name')){
                        this.m_title=localStorage.getItem('details_name')
                        return
                      }
                    },200)
                  }
                  break;
                }*/
            }
          }
          this.navs.forEach(v=>{
            //可以考虑用查找是否存在符合条件的字符串
            if(v.to==n){
              this.nowNav = v.name
            }
            //如果没找到对应的 保持上一个
          })
          if(!this.nowNav){
            this.nowNav = this.navs[0].name
          }
        },

      },
      search_value(){
        if(this.search_value.length==0){
          this.$emit('search_in','')
        }
      },
      info(o){
        console.log(o)
        this.avatar=loding_s
        this.msg='退出登录'
      },
      title(){
        if(this.title.length>=0){
          this.m_title=this.title
        }
      }
    },
    created() {
      if(localStorage.getItem('token')){
        this.avatar=loding_s
        this.msg='退出登录'
      }
    },
  }
</script>

<style lang="scss" scoped>
	@import '~animate.css';
  @media all {
    .header_bg {
      height: 73px;
      width: 100%;
      background: url("../assets/Mask group.png") center center/100% 92px no-repeat;
      display: flex;
      align-items: center;
      .logo {
        width: 38px;
        height: 39px;
        border-radius: 3px;
        background: url("../assets/del_header_logo.png") 0 0/38px 39px no-repeat;
        overflow: hidden;
        display: inline-block;
        float: left;
        margin-right: 11px;
        &+span {
          font-size: 25px;
          font-weight: bold;
          color: #3A4479;
        }
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        /*display: flex;
        justify-content: space-evenly;
        align-items: center;*/
        a{
          margin-right: 37px;
          display: inline-block;
          li {
            float: left;
            font-size: 16px;
            font-weight: bold;
            color: #26306A;
            cursor: pointer;
            line-height: 35px;
          }
        }
        /*li {
          float: left;
          font-size: 16px;
          font-weight: bold;
          color: #26306A;
          cursor: pointer;
          height: 22px;
        }*/
        .nav_select {
          font-size: 21px !important;
          font-weight: bold !important;
          line-height: 30px !important;
          color: #219CFC;
          /*background: linear-gradient(270deg, #0373E6 0%, #219CFC 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;*/
          position: relative;
          height: 35px !important;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;

          .no_affected_size {
            height: 4px;
            width: 38px;
          }
        }
      }

      .cha {
        width: 16px;
        height: 16px;
        background: url("../assets/close.png") 0 0/100% 100% no-repeat;
        cursor: pointer;
        position: absolute;
        top: -8px;
        left: 48px;
      }
      .search_input {
        width: 429px;
        height: 36px;
        background: #FFFFFF;
        border-radius: 18px;
        position: absolute;
        left: 83px;
        top: -18px;
        padding: 5px 10px;

        &>input {
          border: none;
          outline: none;
          height: 100%;
          width: 100%;
          line-height: 36px;
        }
      }

      img {
        width: 25px;
        height: 25px;
        cursor: pointer;
      }

      .search_img {
        position: absolute;
        left: 530px;
        top: -13px;
      }

      .vertical_line {
        height: 35px;
        width: 0px;
        border-left: 2px solid #E8E4E4;
        margin: 0 18px 0 14px;
      }

      .avatar {
        width: 35px;
        height: 35px;
        margin-right: 14px;
        img{
          width: 100%;
          height: 100%;
        }
      }

      button {
        width: 96px;
        height: 35px;
        border-radius: 21.5px;
        background: url("../assets/Rectangle 3.png") center center/96px 35px no-repeat;
        cursor: pointer;
        border: 0;
        font-size: 15px;

        font-weight: bold;
        color: #FFFFFF;
        overflow: hidden;
      }

    }
  }
  @media all and (min-width: 768px) and (max-width: 991px) {
    .header_bg {
      .logo {
        width: 30px!important;
        height: 31px!important;
        background: url("../assets/del_header_logo.png") 0 0/30px 31px no-repeat!important;
        margin-right: 5px!important;
        &+span {
          font-size: 20px!important;
          font-weight: bold;
          color: #3A4479;
          float: left;
        }
      }
      ul {
        a{
          margin-right: 20px;
        }
        li {
          float: left;
          font-size: 14px!important;
          font-weight: bold;
          color: #26306A;
          cursor: pointer;
        }

        .nav_select {
          font-size: 16px !important;
        }
      }

/*      img {
        width: 18px!important;
        height: 18px!important;
        cursor: pointer;
      } 搜索图标*/
      .cha{
        left: 11px;
      }
      .search_input{
        width: 333px;
        left: 37px;
      }
      .search_img{
        left: 379px;
      }

      .vertical_line {
        height: 25px;
        width: 0px;
        border-left: 2px solid #E8E4E4;
        margin: 0 9px 0 7px!important;
      }

      .avatar {
        width: 28px!important;
        height: 28px!important;
        margin-right: 7px!important;
        img{
          width: 100%;
          height: 100%;
        }
      }

      button {
        width: 86px;
        height: 30px;
        border-radius: 21.5px;
        background: url("../assets/Rectangle 3.png") center center/96px 35px no-repeat;
        cursor: pointer;
        border: 0;
        font-size: 13px!important;

        font-weight: bold;
        color: #FFFFFF;
        overflow: hidden;
      }

    }

  }
  @media all and (max-width:766px){
    .m_header{
      width: 100%;
      padding: 0 10%;
      height: 11.77vw;
      background: url("../assets/header.png") center top/100% 100% no-repeat;
      font-size: 4.533vw;
      color: white;
      line-height: 11.77vw;
      text-align: center;
      /*display:flex!important;
      justify-content: center;
      align-items: center;*/
      position: relative;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      img{
        height: 7.77vw;
      }
      .arrow_left{
        width: 4vw;
        height: 4vw;
        border: 0.4vw solid #FFFFFF;
        border-left-width: 0;
        border-bottom-width: 0;
        transform: matrix(-0.71,0.71,0.71,0.71,0,0);
        position: absolute;
        left: 6.8vw;
        top: 3.885vw;
      }
    }
  }
</style>
